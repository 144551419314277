






<script>


import FeeSetupDialog from './agent_fee_setup_dialog.vue'
import FeeEditDialog from './agent_fee_edit_dialog.vue'

import {getBrokerageApi} from '@/api/brokerage'
import {financial} from '@/api/misc'

/**
 * Agent Fee Info component
 */

export default {



    props: ['data'],
    components: {
      FeeSetupDialog,
      FeeEditDialog
    },


    data() {

        return {

            fields: [
              {
                key: "Name",
                sortable: true,
              },
              {
                key: "Code",
                sortable: true,
              },
              {
                key: "Fee",
                sortable: true,
              },
              {
                key : "action"
              }
            ],
            current_edit_fee : {}
        }

    },
    methods: {

      f(n, d=2) {
        return financial(n, d)
      },

      agent_fee_save(newFee) {
       
        let fee = {
          fee_code : newFee.code,
          agent_id : this.data.id,
          fee_amount : newFee.fee_type == 'FIXED' ? newFee.amount : 0,
          fee_rate : newFee.fee_type == 'RATE' ? newFee.amount  / 100.0: 0,
        }

        getBrokerageApi().agentApi.agent_add_fee(fee).then((res) => {
          if (res.errCode == 0) {
            this.$alertify.message("Fee Added");
            this.$bvModal.hide('modal-fee-setup-dialog')

            let obj = res.data[0]
            const fr = 
              Object.keys(obj).reduce((acc, k) => {
                acc[k.toLowerCase()] = obj[k];
                return acc;
              }, {});

              this.data.fee_items.push(fr)


          } else {
            this.$alertify.error("Fee Add  Failed " + res.errCode);
          }
        })
      },

      remove_agent_fee(fee) {
        let fee_id = fee.id
        getBrokerageApi().agentApi.agent_remove_fee({fee_id : fee.id}).then((res) => {
          if (res.errCode == 0) {
            this.$alertify.message("Removed");
            let idx = this.data.fee_items.findIndex((s) => s.id == fee_id)
            if (idx >= 0) {
              this.data.fee_items.splice(idx, 1)
            }
          } else {
            this.$alertify.error("Remove Agent Fee Failed ");
          }
        })
      },

      open_fee_edit_dialog(fee) {
        this.current_edit_fee = fee
        this.$bvModal.show('modal-fee-edit-dialog_'+fee.id)
      },

      agent_fee_update(newFee) {
        let fee = {
          fee_code : this.current_edit_fee.code,
          agent_id : this.data.id,
          fee_amount : newFee.fixed_type == 'FIXED' ? newFee.amount : newFee.amount  / 100.0,
          fixed_type : newFee.fixed_type,
        }

        getBrokerageApi().agentApi.fee_update_only(fee).then((res) => {
          if (res.errCode == 0) {
            let fi = this.data.fee_items.find((fi) => fi.code == fee.fee_code)
            if (fi) {
              if (newFee.fixed_type == 'FIXED') {
                fi.fixed_fee =  newFee.amount
              } else if (newFee.fixed_type == 'RATE') {
                fi.fixed_rate =  newFee.amount
              }
            }
            this.$alertify.message("Fee Updated");
            this.$bvModal.hide('modal-fee-edit-dialog_'+this.current_edit_fee.id)

          } else {
            this.$alertify.error("Fee Edit  Failed " + res.errCode);
          }
        })

        
      }

    },

    created() {

    },

    beforeUpdated() {
     
    },

    mounted() {
      
    }

};
</script>


<template>
    <div class="row">

      <div class="d-flex align-items-start">
            <div class="flex-grow-1">
                <h5 class="card-title"></h5>
            </div>
            <div class="flex-shrink-0">
                <b-dropdown right variant="white" toggle-class="btn-link text-dark p-0" menu-class="dropdown-menu-end">
                    <template v-slot:button-content>
                        <i class="uil uil-ellipsis-h"></i>
                    </template>
                    <b-dropdown-item v-b-modal.modal-fee-setup-dialog>Add Agent Fee</b-dropdown-item>
                </b-dropdown>


                
                <b-modal centerd :id="'modal-fee-setup-dialog'" title="Fee Setup" size="lg" hide-footer>
                    <FeeSetupDialog @cancel="$bvModal.hide('modal-fee-setup-dialog')"
                        @confirm="agent_fee_save" />
                </b-modal>
            </div>
        </div>

        <div class="card-body">
            
            <div class="table-responsive">
              <div class="table align-middle table-nowrap">
                <b-table
                  :items="data.fee_items"
                  :fields="fields"
                  responsive="sm"
                  class="table-check"
                >

                  <template #cell(Name)="data">
                    {{ data.item.name }}
                  </template>

                  <template #cell(Code)="data">
                    {{ data.item.code }}
                  </template>

                  <template #cell(Fee)="data">
                    {{ data.item.fixed_type == 'FIXED' ?   data.item.fixed_fee :  f(data.item.fixed_rate * 100)+'%'}}
                  </template>

                  <template #cell(action)="data">
                    <b-dropdown variant="light" right size="sm">
                      <template v-slot:button-content>
                        <i class="uil uil-ellipsis-h"></i>
                      </template>
                      <b-dropdown-item @click="open_fee_edit_dialog(data.item)">Edit</b-dropdown-item>
                    </b-dropdown>
                    <b-modal centerd :id="'modal-fee-edit-dialog_'+data.item.id" title="Fee Edit" size="lg" hide-footer>
                      <FeeEditDialog :fee="data.item" @cancel="$bvModal.hide('modal-fee-edit-dialog_'+data.item.id)" @confirm="agent_fee_update" />
                    </b-modal>
                  </template>
                </b-table>
              </div>
            </div>
            <!-- end table responsive -->
        </div>
        <!-- end card body -->


    </div>
    <!-- end row -->
</template>