





<script>


/**
 * Agent Basic Info component
 */

import { financial } from '@/api/misc'
import { getBrokerageApi } from "@/api/brokerage"

import flatPickr from "vue-flatpickr-component";
import "flatpickr/dist/flatpickr.css";


export default {



  props: ['data'],
  components: {
    flatPickr
  },


  data() {

    return {
      fields: [
        {
          key: "FeeName",
        },
        {
          key: "Fee",
          sortable: true,
        },
        {
          key: "FeeTax",
          sortable: true,
        },
      ],

      query_month : '',
      bills : []
    }

  },
  methods: {
    f(n, d = 2) {
      return financial(n, d)
    },

    query_agent_bill() {
      
      getBrokerageApi().agentApi.agent_bill({ agent_id: this.data.id, bill_month : this.query_month }).then((res) => {
          if (res.errCode == 0) {
            this.bills = res.data
          }
        })
    },

    

  },

  created() {

  },

  mounted() {
    

  },


  watch: {
    data(new_val) {
      this.bill = new_val.bill
    }
  }

};
</script>


<template>
  

      <div class="row" style="min-height: 50vh;">
        <div class="col-lg-12">
          <div class="row mb-3">
            <div class="col-md-2 mt-2">
              <h4 class="font-size-15">Query Bill Month</h4>
            </div>
            <div class="col-md-4">
              <flat-pickr v-model="query_month" aria-placeholder="Select Bill Month" :config="{dateFormat: 'Ym', altFormat:'Y-m', ariaDateFormat :'Y-m' }" placeholder="Select Bill Month" class="form-control" @on-change="query_agent_bill" ></flat-pickr>
            </div>
          </div>


          <div class="card" v-for="bill, idx in bills" :key="'agent_bill_'+idx+'_'+bill.id">
            <div class="card-body">

              <div class="invoice-title">
                <div class="text-muted">
                  <h4 class="float-end font-size-15">

                    <span class="badge bg-success font-size-12 ms-2" v-if="bill.is_his">
                      <span v-if="bill.is_finish_flag">Paid</span>
                      <span v-else>Pending</span>
                    </span>
                    <span class="badge bg-success font-size-12 ms-2" v-else> Preview</span>
                  </h4>
                  <p class="mb-1">Agent:{{ data.full_name }}</p>
                  <p class="mb-1">Bill Statement Month:{{ bill.bill_month }}</p>
                  <p class="mb-1">Invoice #:{{ bill.invoice_number }}</p>
                  <p class="mb-1">{{ data.address }}</p>
                  <p class="mb-1"><i class="uil uil-envelope-alt me-1"></i> {{ data.email }}</p>
                  <p><i class="uil uil-phone me-1"></i>{{ data.phone }}</p>
                
                </div>
              </div>

              <hr class="my-4" />
              <div class="py-2">
                <h5 class="font-size-15">Bill Summary</h5>

                <div class="table-responsive">
                  <table class="table align-middle table-nowrap table-centered mb-0">
                    <thead>
                      <tr>
                        <th style="width: 100px;">No.</th>
                        <th>Fee Code</th>
                        <th>Fee Name</th>
                        <th>Note</th>
                        <th class="text-end">Amount</th>
                        <th class="text-end">GST</th>
                        <th class="text-end" style="width: 120px;">Total</th>
                      </tr>
                    </thead>
                    <!-- end thead -->
                    <tbody>
                      <tr v-for="(item, idx) in bill.fees" :key="'bill_item_'+idx">
                        <th scope="row">{{ idx + 1 }}</th>
                        <td>
                          <div>
                            <h5 class="text-truncate font-size-14 mb-1">{{ item.fee_code }} </h5>
                            <p class="text-muted mb-0"></p>
                          </div>
                        </td>
                        <td>
                          <div>
                            <h5 class="text-truncate font-size-14 mb-1">{{ item.fee_name }} </h5>
                            <p class="text-muted mb-0"></p>
                          </div>
                        </td>
                        <th>{{ item.summary?item.summary :'' +' ' + `${item.regbv? ('Inv:'+item.regbv.invoice+' Address:'+ item.regbv.address+' Completed Date:'+item.regbv.completion_date):''}` }} </th>

                        <td class="text-end">${{ f(item.fee).toLocaleString() }}</td>
                        <td class="text-end">${{ f(item.fee_tax).toLocaleString() }}</td>
                        <td class="text-end">${{ f(item.fee + item.fee_tax).toLocaleString() }}</td>
                      </tr>
                      <!-- end tr -->

                      <tr>
                        <th scope="row" colspan="4" class=" border-0 text-end">
                          Sub Total
                        </th>
                        <td class="border-0 text-end">${{ f(bill.sub_amount ).toLocaleString() }}</td>
                        <td class="border-0 text-end">${{ f(bill.sub_amount_gst ).toLocaleString() }}</td>
                        <td class="border-0 text-end">${{ f(bill.sub_amount +bill.sub_amount_gst ).toLocaleString() }}</td>
                      </tr>
                      <!-- end tr -->
                      <tr>
                        <th scope="row" colspan="4" class="border-0 text-end">
                          Credit :
                        </th>
                        <td class="border-0 text-end"></td>
                        <td class="border-0 text-end"></td>
                        <td class="border-0 text-end">- ${{ f(bill.credit).toLocaleString() }}</td>
                      </tr>
                      <!-- end tr -->
                      <tr>
                        <th scope="row" colspan="4" class="border-0 text-end">
                          Total
                        </th>
                        <td class="border-0 text-end"></td>
                        <td class="border-0 text-end"></td>
                        <td class="border-0 text-end">
                          <h4 class="m-0 fw-semibold">${{  f(bill.sub_amount +bill.sub_amount_gst +bill.credit).toLocaleString() }}</h4>
                        </td>
                      </tr>
                      <!-- end tr -->
                    </tbody>
                    <!-- end tbody -->
                  </table>
                  <!-- end table -->
                </div>
                <!-- end table responsive -->
               
              </div>
            </div>
          </div>
          <!-- end card for-->

          <div class="d-print-none mt-4">
            <div class="float-end">
              <a href="javascript:void(0);" class="btn btn-primary w-md">Export Pdf</a>
            </div>
          </div>


        </div>
        <!-- end col -->
      </div>
      <!-- end row -->

</template>