<script>


/**
 * Fee Setup  component
 */



import {
    required,
} from "vuelidate/lib/validators";
import Choices from "choices.js";
import { financial } from '@/api/misc'

import {searchFee} from '@/api/misc'


export default {

    emits: ['confirm', 'cancel'],

    props: ['category'],
   
    components: {

    },

    validations: {
       
        amount : {required},
        gst : {required}
    },



    data() {

        return {
            submitted: false,
            
            fees: [],

            fee_list_choice:{},
            fee_name : '',
            fee_code : '',
            amount : '',
            total    : '',
            fee_type : 'FIXED',
            tax_flag : 1,
            note     : '',
            gst      : '',
            fee_tri  : '',
            fee_char : ''

        }

    },
    methods: {
        formSubmit() {
            this.submitted = true;
            this.$v.$touch();
            let fee = {
                name         : this.fee_name,
                code         : this.fee_code,
                amount       : this.amount,
                gst          : this.gst,
                fee_type     : this.fee_type,
                trigger_type : this.fee_tri,
                charge_type  : this.fee_char,
                category     : this.fee_cate,
                note         : this.note
            }

            
            if (this.$v.$error == false) {
                this.$emit('confirm', fee)
            }
        },

        onSearchedFee(e) {
            searchFee({"search_str" : e.detail.value}).then((res) =>{
                this.fees = []
                res.data.map((f) =>{
                    this.fees.push({
                        label : `${f.name}`,
                        value : f.code,
                        fee : f
                    })
                })
                if (this.category) {
                    this.fees = this.fees.filter(e => e.fee.category == this.category)
                }
                this.fee_list_choice.clearChoices()
                this.fee_list_choice.setChoices(this.fees)
                this.fee_list_choice.showDropdown()
            })
           
        },

        onSelectFee(ev) {
            let f = this.fees.find((f) => f.value == ev.detail.value)
            let fee = f.fee
            this.fee_name = fee.name
            this.tax_flag = fee.TAX_FLAG
            this.fee_type = fee.FIXED_TYPE
            this.fee_cate = fee.CATEGORY
            this.fee_tri  = fee.trigger_type
            this.fee_char = fee.charge_type
            this.amount   = financial(fee.FIXED_TYPE =='FIXED'? fee.FIXED_FEE:fee.FIXED_RATE * 100, 2)
            if (this.tax_flag == '1' && fee.FIXED_TYPE =='FIXED') {
                this.gst    = financial(Number(this.amount) * 0.05, 2)
            }
            this.total    = financial(this.amount + this.gst, 2)
        }

    },

    created() {

       
    },

    mounted() {

        this.fee_list_choice = new Choices('#fee_list_choice', {
            placeholderValue: "This is a placeholder set in the config",
            searchPlaceholderValue: "search by name",
        })
        

    }

};
</script>

<template>
    <div class="card">
        <div class="card-body">
            <form class="needs-validation" @submit.prevent="formSubmit">
                <div class="row">
                    <div class="col-md-4">
                        <div class="mb-3">
                            <label for="fee_list_choice" class="form-label font-size-13 text-muted"  >Fee Type</label>
                            <select
                                class="form-control"
                                data-trigger
                                v-model="fee_code"
                                name="fee_list_choice"
                                id="fee_list_choice"
                                @search="onSearchedFee"
                                @change="onSelectFee"
                                >
                                
                                <option value=""></option>
                            </select>
                           
                        </div>
                    </div>

                    <div class="col-md-2">
                        <div class="mb-3">
                            <label for="validationCustom01">Code</label>
                            <input id="validationCustom01" v-model="fee_code" type="text" class="form-control"
                                readonly="true" />
                        </div>
                    </div>

                    <div class="col-md-2">
                        <div class="mb-3">
                            <label for="validationCustom01">{{fee_type =='RATE' ? 'Rate(%)' :'Amount'}}</label>
                            <input id="validationCustom01" v-model="amount" type="text" class="form-control"
                                placeholder="$"  :class="{
                                    'is-invalid':  $v.amount.$error,
                                }" />
                            <div v-if=" $v.amount.$error" class="invalid-feedback">
                                <span v-if="!$v.amount.required">This value is required.</span>
                            </div>
                        </div>
                    </div>

                  

                    <div class="col-md-2">
                        <div class="mb-3">
                            <label >GST</label>
                            <input id="validationCustom01" v-model="gst" type="text" class="form-control"
                                placeholder="$"  :class="{
                                    'is-invalid':  $v.gst.$error,
                                }" />
                            <div v-if=" $v.gst.$error" class="invalid-feedback">
                                <span v-if="!$v.gst.required">This value is required.</span>
                            </div>
                        </div>
                    </div>

                    <div class="col-md-2">
                        <div class="mb-3">
                            <label >Total</label>
                            <input id="validationCustom01" v-model="total" type="text" class="form-control"
                                placeholder="$" readonly />
                           
                        </div>
                    </div>

                   
                </div>
                <!-- end row-->

                <div class="row">
                    <div class="col-md-6">
                        <div class="mb-3">
                            <label for="fee_list_choice" class="form-label font-size-13 text-muted"  >Note</label>
                            <input id="validationCustom01" v-model="note" type="text" class="form-control"
                                placeholder="Note"   />
                           
                        </div>
                    </div>
                </div>



                <div class="row">
                    <div class="col-md-4">

                    </div>
                    <div class="col-md-4">
                        <div class="d-flex flex-wrap gap-2">
                            <b-button variant="secondary" type="button" @click="$emit('cancel')">Cancel</b-button>
                            <b-button variant="primary" type="submit">Save</b-button>
                        </div>
                    </div>
                    <div class="col-md-4">

                    </div>

                </div>
                <!-- end row-->


            </form>
        </div>
    </div>
    <!-- end card -->
</template>