<script>


import appConfig from "@/app.config";

export default {
    page: {
        title: "Agent",
        meta: [
            {
                name: "description",
                content: appConfig.description,
            },
        ],
    },

    props: {

        agent_id: {
            type: Number
        }
    },

    data() {
        return {

            folder_list : [
                {name : 'Forms'},
                {name : 'Contract'},
                {name : 'Other'},
            ],

            fileData: [
                {
                    icon: "fas fa-file-alt text-primary",
                    name: "Index.html",
                    date: "12-10-2020, 09:45",
                    size: "09 KB",
                },
                {
                    icon: "fas fa-file-archive text-warning",
                    name: "Project-A.zip",
                    date: "11-10-2020, 17:05",
                    size: "115 KB",
                },
                {
                    icon: "fas fa-image text-muted",
                    name: "Img-1.jpeg",
                    date: "11-10-2020, 13:26",
                    size: "86 KB",
                },
                {
                    icon: "fas fa-file-alt text-muted",
                    name: "Update list.txt",
                    date: "10-10-2020, 11:32",
                    size: "08 KB",
                },
                {
                    icon: "fas fa-folder text-warning",
                    name: "Project B",
                    date: "10-10-2020, 10:51",
                    size: "72 KB",
                },
                {
                    icon: "fas fa-file-alt text-muted",
                    name: "Changes list.txt",
                    date: "09-10-2020, 17:05",
                    size: "07 KB",
                },
                {
                    icon: "fas fa-image text-success",
                    name: "Img-2.png",
                    date: "09-10-2020, 15:12",
                    size: "31 KB",
                },
                {
                    icon: "fas fa-folder text-warning",
                    name: "Project C",
                    date: "09-10-2020, 10:11",
                    size: "20 KB",
                },
                {
                    icon: "fas fa-file-alt text-primary",
                    name: "Starter-page.html",
                    date: "08-10-2020, 03:22",
                    size: "11 KB",
                },
            ]
        }
    }
}


</script>

<template>
    <div class="row">
        <div class="mt-4">
            <div class="d-flex flex-wrap">
                <h5 class="font-size-16 me-3">Folder</h5>

                <div class="ms-auto">
                    <a href="javascript: void(0);" class="fw-medium text-reset">View All</a>
                </div>
            </div>
            <hr class="mt-2" />

            <div class="row">
                <div class="col-xl-3 col-sm-3" v-for="(item, idx) in folder_list" :key="'agent_folder_'+idx">
                    <div class="card shadow-none border">
                        <div class="card-body p-3">
                            <div class="">
                                <div class="float-end ms-2">
                                    <b-dropdown variant="white" right toggle-class="font-size-16 text-muted p-0">
                                        <template v-slot:button-content>
                                            <i class="uil uil-ellipsis-h"></i>
                                        </template>
                                        <b-dropdown-item>Rename</b-dropdown-item>
                                        <b-dropdown-item>Remove</b-dropdown-item>
                                    </b-dropdown>
                                </div>
                                <div class="avatar-sm me-3 mb-3">
                                    <div class="avatar-title bg-transparent rounded">
                                        <i class="fas fa-folder font-size-24 text-warning"></i>
                                    </div>
                                </div>
                                <div class="d-flex">
                                    <div class="overflow-hidden me-auto">
                                        <h5 class="font-size-14 text-truncate mb-1">
                                            <a href="javascript: void(0);" class="text-body">{{ item.name }}</a>
                                        </h5>
                                        <p class="text-muted text-truncate mb-0">
                                            12 Files
                                        </p>
                                    </div>
                                    <div class="align-self-end ms-2">
                                        <p class="text-muted mb-0">6GB</p>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <!-- end card body-->
                    </div>
                    <!-- end card -->
                </div>
                <!-- end col -->
            </div>
            <!-- end row -->

        </div>



        <div class="mt-4">
            <div class="d-flex flex-wrap">
                <h5 class="font-size-16 me-3">Files</h5>

                <div class="ms-auto">
                    <a href="javascript: void(0);" class="fw-medium text-reset"></a>
                </div>
            </div>
            <hr class="mt-2" />

            <div class="table-responsive">
                <table class="table align-middle table-nowrap table-hover mb-0">
                    <thead>
                        <tr>
                            <th scope="col">Name</th>
                            <th scope="col">Date Modified</th>
                            <th scope="col" colspan="2">Size</th>
                        </tr>
                        <!-- end tr -->
                    </thead>
                    <!-- end thead -->
                    <tbody>
                        <tr v-for="(item, index) in fileData" :key="index">
                            <td>
                                <a href="javascript: void(0);" class="text-dark fw-medium"><i
                                        :class="`${item.icon} align-middle me-2`"></i>
                                    {{ item.name }}</a>
                            </td>
                            <td>{{ item.date }}</td>
                            <td>{{ item.size }}</td>
                            <td>
                                <b-dropdown variant="white" toggle-class="font-size-16 text-muted p-0">
                                    <template v-slot:button-content>
                                        <i class="uil uil-ellipsis-h"></i>
                                    </template>
                                    <b-dropdown-item>Open</b-dropdown-item>
                                    <b-dropdown-item>Edit</b-dropdown-item>
                                    <b-dropdown-item>Rename</b-dropdown-item>
                                    <b-dropdown-divider></b-dropdown-divider>
                                    <b-dropdown-item>Remove</b-dropdown-item>
                                </b-dropdown>
                            </td>
                        </tr>
                        <!-- end tr -->
                    </tbody>
                    <!-- end tbody -->
                </table>
                <!-- end table -->
            </div>
            <!-- end table responsive -->
        </div>

    </div>
</template>