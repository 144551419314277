





<script>


/**
 * Agent Month Statment Info component
 */





import { financial } from '@/api/misc'

import {
  basicColumnChart,
  columnDatalabelChart,
} from "./data";

export default {



  props: ['data'],
  components: {
   
    
  },


  data() {

    return {
      basicColumnChart: basicColumnChart,
      columnDatalabelChart: columnDatalabelChart,
      fields: [
        {
          key: "FeeName",
        },
        {
          key: "FeeCode",
        },
        {
          key: "Note",
        },

        {
          key: "Fee",
          sortable: true,
        },
        {
          key: "FeeTax",
          sortable: true,
        },
        {
          key: "Summary",
          sortable: false,
        },
      ],

      agent_id: '',
      currentPage: 1,
      perPage: 40,
      totalRows: 0,
      records: [],
    }

  },

  watch: {
    data: function (newValue, oldValue) {
      (oldValue);
      this.agent_id = newValue.id
    
    }
  },
  methods: {

    f(n, d = 2) {
      return financial(n, d)
    },

   

  },

  created() {

  },

  mounted() {

  }

};
</script>


<template>
  <div class="row">
    <div class="row">
      <div class="col-lg-6">
        <div class="card">
          <div
            class="
              card-header
              justify-content-between
              d-flex
              align-items-center
            "
          >
            <h4 class="card-title">Basic Column Charts</h4>
            <a
              href="https://apexcharts.com/vue-chart-demos/column-charts/basic/"
              target="_blank"
              class="btn btn-sm btn-soft-secondary"
              >Docs <i class="mdi mdi-arrow-right align-middle"></i
            ></a>
          </div>
          <!-- end card header -->
          <div class="card-body">
            <apexchart
              class="apex-charts"
              height="350"
              dir="ltr"
              :series="basicColumnChart.series"
              :options="basicColumnChart.chartOptions"
            ></apexchart>
          </div>
        </div>
        <!--end card-->
      </div>
      <!--end col-->
      <div class="col-lg-6">
        <div class="card">
          <div
            class="
              card-header
              justify-content-between
              d-flex
              align-items-center
            "
          >
            <h4 class="card-title">Deal Commission</h4>
            
          </div>
          <!-- end card header -->
          <div class="card-body">
            <apexchart
              class="apex-charts"
              height="350"
              dir="ltr"
              :series="columnDatalabelChart.series"
              :options="columnDatalabelChart.chartOptions"
            ></apexchart>
          </div>
        </div>
        <!--end card-->
      </div>
      <!--end col-->
    </div>
    <!-- end row -->

  </div>
  <!-- end row -->
</template>